body {
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  overscroll-behavior: contain;
  margin-right: 0 1px 0 0 !important;
  padding-right: 0px !important;
}

header {
  padding-right: 0px !important;
}

:root {
  --toastify-icon-color-success: white !important;
}

.MuiTab-root.Mui-selected {
  /* background-color: #f4f6f9 !important; */
  background-color: #409a6510 !important;
}

.profile-tabs.Mui-selected {
  background-color: white !important;
}

.nestable-list {
  margin-top: 0 !important;
}

.nestable-item {
  margin-top: 0 !important;
}

.nestable-item.is-dragging:before {
  background-color: #47a06e1e !important;
}

.lastTerritory {
  border-radius: 0 0 0 4px;
}

.nestable-item--with-children.nestable-item--children-open
  .nestable-list
  .nestable-item:last-child {
  border-radius: 0 0 0 4px;
}
/* .Mui-error * {
  border-color: #ff3737 !important;
} */
.MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeSmall.MuiFormHelperText-contained {
  font-size: 0.7rem;
}

.slick-disabled * {
  display: none;
}

.pac-container {
  z-index: 100000;
}

.progressive-loading {
  object-fit: cover;
  object-position: center;
  filter: blur(10px);
}

.progressive-loaded {
  object-fit: cover;
  object-position: center;
  filter: blur(0);
  transition: filter 0.3s linear;
}

.Toastify__toast--success.middle-toast {
  background-color: #363531;
  color: #fff;
  white-space: pre-wrap;
  font-size: 14px;
  width: 367px;
  pointer-events: all;
}

.Toastify__toast--success.import-toast {
  background-color: #363531;
  color: #fff;
  white-space: pre-wrap;
  font-size: 14px;
  width: 367px;
  pointer-events: all;
}

.Toastify__toast--success {
  text-align: center;
}

.Toastify__toast--error {
  text-align: center;
}

.Toastify__toast-container {
  width: fit-content !important;
}

.Toastify__toast-container.Toastify__toast-container--top-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Toastify__toast-container.Toastify__toast-container--bottom-center:has(.middle-toast) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20%);
  pointer-events: none;
}

.Toastify__toast--success.middle-toast .Toastify__close-button {
  color: #fff;
  opacity: 1;
}

.initial-point {
  width: 10px;
  height: 10px;
  background-color: #47a06d;
  border-radius: 50%;
}

.delete-point {
  width: 10px;
  height: 10px;
  background-color: #47a06d;
  border-radius: 50%;
}

.gm-fullscreen-control[aria-pressed="true"] {
  bottom: 83px !important;
  right: 12px !important;
}

.gm-fullscreen-control[aria-pressed="false"] {
  bottom: 83px !important;
  right: 12px !important;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes shadowPulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(64, 154, 101, 0.4);
    box-shadow: 0 0 0 0 rgba(64, 154, 101, 0.9);
  }
  70% {
    -moz-box-shadow: 0 0 0 5px rgba(64, 154, 101, 0);
    box-shadow: 0 0 0 5px rgba(64, 154, 101, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(64, 154, 101, 0);
    box-shadow: 0 0 0 0 rgba(64, 154, 101, 0);
  }
}

.gm-bundled-control-on-bottom {
  right: 52px !important;
  bottom: 217px !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  width: unset !important;
  max-width: unset !important;
  overflow: visible !important;
}

.infinite-scroll-custom-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.infinite-scroll-custom-scrollbar {
  scrollbar-width: 0px;
}

/* Custom styles scrollbar */
.windows-scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 1px grey;  */
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bababa;
}

/* .Toastify__toast.warn-toast {
  padding: 12px 8px 12px 10px;
  border-radius: 8px;
  min-height: auto;
}
.Toastify__toast-body.warn-toast-body {
  padding: 0px 0px 0px 0px;
}
.warn-toast .Toastify__close-button {
  padding-left: 8px;
  padding-top: 4px;
} */
